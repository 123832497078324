// import { combineReducers } from 'redux';
//
//
// const strainsReducer = () => {
//   return [
//     { title: 'Kryptonic Blue', type: 'Hybrid' },
//     { title: 'Purple Monk', type: 'Indica' },
//     { title: 'Orange Waffle', type: 'Sativa' },
//   ]
// };
//
// const selectedStrainReducer = (selectedStrain=null, action) => {
//   if (action.type === 'STRAIN_SELECTED') {
//     return action.payload;
//   }
//   return selectedStrain;
// };
//
//
// export default combineReducers({
//   strains: strainsReducer,
//   selectedStrain: selectedStrainReducer
// })


import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import AuthReducer from './AuthReducer';
import StrainsReducer from './StrainsReducer';
import SelectedStrainReducer from './SelectedStrainReducer';
import SearchStrainsReducer from './SearchStrainsReducer';
import FetchReviewsReducer from './FetchReviewsReducer';
import FetchImages from './FetchImagesReducer';
import ReviewCount from './ReviewCount';
import StrainPostCount from './StrainPostCount';
import AgeCheckedReducer from './AgeCheckedReducer';

export default combineReducers({
  auth: AuthReducer,
  strains: StrainsReducer,
  selectedStrain: SelectedStrainReducer,
  form: formReducer,
  searchingForStrain: SearchStrainsReducer,
  reviews: FetchReviewsReducer,
  strainImages: FetchImages,
  reviewCount: ReviewCount,
  strainPostCount: StrainPostCount,
  isOfAge: AgeCheckedReducer,
})
