import React, { Component } from 'react';
import '../config/contact.css';
import colors from '../config/colors';
import { Link } from 'react-router-dom';
import {
  Button,
} from 'semantic-ui-react';

class ContactDispensarySuccess extends Component {

constructor(props) {
	super(props);
	this.state = {
	}
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
	return(
  	<div className="contactForm">
	  <h1 className='subscribeh1'>Thank you, we have recieved your request!</h1>
	  <p style={{ marginBottom: 30 }}>We are looking forward to further demonstrate our services to you. We will get back to you shortly, please keep an eye on your inbox. </p>
	  
	  <p style={{ marginBottom: 50 }}>Stay Safe, <br></br>The Cannections Team</p>
	  <Link to="/" className="item">
        <Button size='large' style={styles.ctaButtonOG}>Return Home</Button>
       </Link>
  	</div>
	);
  }

}

const styles = {
	ctaButtonOG: {
	 //  maxWidth: 150, 
	  color: colors.white, 
	  backgroundColor: colors.skizzuGreen, 
	  borderRadius: 50,
	},
   }

export default ContactDispensarySuccess;
