import {
  STRAIN_SELECTED,
  STRAIN_UNMOUNTED
} from '../actions/types';
//state argument is not application state, only the state the reducer is responsible for
export default function (state = null, action) {
  switch (action.type) {
    case STRAIN_SELECTED:
      return action.payload;
    case STRAIN_UNMOUNTED:
      return null;
      default:
  }
  return state;
}
