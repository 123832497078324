import {
    REVIEW_COUNT
  } from '../actions/types';
  //state argument is not application state, only the state the reducer is responsible for
  export default function (state = '', action) {
    switch (action.type) {
      case REVIEW_COUNT:
        return action.payload;
      // case STRAIN_UNMOUNTED:
      //   return {};
        default:
    }
    return state;
  }
  