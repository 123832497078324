// import {
//   STRAINS_FETCH_SUCCESS
// } from '../actions/types';

// const INITIAL_STATE = {};

// export default (state = INITIAL_STATE, action) => {
//   switch (action.type) {
//     case STRAINS_FETCH_SUCCESS:
//       return action.payload;
//     default:
//       return state;
//   }
// };


import {
  STRAINS_FETCH_SUCCESS,
  // STRAIN_SEARCH,
  // STRAIN_INSTANT_SEARCH,
  STRAIN_FILTER_TYPE,
  STRAIN_FILTER_EFFECT,
  STRAIN_UNMOUNT,
  // FETCH_MY_FAVS,
  // FEATURED_STRAIN_FETCH_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  strains: [],
  lastKey: [],
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case STRAINS_FETCH_SUCCESS:
    return { strains: action.payload, lastKey: action.key };

    // case FEATURED_STRAIN_FETCH_SUCCESS:
    // return { strains: action.payload, lastKey: null };

    case STRAIN_UNMOUNT:
    return { strains: null, lastKey: null };

    // case STRAIN_SEARCH:
    // return { strains: action.payload, lastKey: null };

    case STRAIN_FILTER_TYPE:
    return { strains: action.payload, lastKey: action.key };

    case STRAIN_FILTER_EFFECT:
    return { strains: action.payload, lastKey: action.key };
    

    default:

    return state;
  }
};
