import {
    IMAGES_FETCH_SUCCESS,
    // STRAIN_IMAGES_UNMOUNT
  } from '../actions/types';
  
  const INITIAL_STATE = {};
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case IMAGES_FETCH_SUCCESS:
        return action.payload;
    //   case STRAIN_IMAGES_UNMOUNT:
    //     return null;
      default:
        return state;
    }
  };