export default {
  skizzuGreen: '#71b54e',
  secondaryGreen: '#54ad38',
  secondaryPurple: '#955ad8',
  secondaryOrange: '#e66f00',
  purple: '#7E57C2',
  orange: '#FF9800',
  yellow: '#ffea00',
  blue: '#3498db',
  red: '#FF5050',

  white: '#fff',
  grayIcon: '#fffffe',

  lightBg: '#fafafa',
  darkBg: '#000000',
  darkBg2: '#212121',
  darkBg3: '#303030',
  darkBg4: '#424242',

  activeField: '#393939',

  grayText: '#cccccc',
  border: '#ccc',
  borderLight: '#e2e2e2',

  blackText: '#4D4D4D',
  blackLight: '#6f6f6f',

  transBlack: 'rgba(0, 0, 0, 0.3)',
  transBlackLighter: 'rgba(0, 0, 0, 0.2)'
};
