//Show a map, and note a CTA for dispensaries to havr their shops there ---> to request a demo... 


// List on left, map on right, 

import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
 
const DispensaryMap = ({ text }) => <div>{text}</div>;
 
class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };
 
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 75}}>
        <div style={{ height: '100vh', width: '70%' }}>
          <GoogleMapReact
            // bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
          >
            <DispensaryMap
              lat={59.955413}
              lng={30.337844}
              text="My Marker"
            />
          </GoogleMapReact>
        </div>

        <div>
          <h1>List goes here...</h1>
        </div>
      </div>
    );
  }
}
 
export default SimpleMap;