import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import colors from '../config/colors';
import {
  Button,
  Grid,
  Container,
  Icon,
  Image,
  Transition,
  div
} from 'semantic-ui-react';

class NotFound extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentWillMount() {
    // this.setState({ height: window.innerHeight - 70 + 'px' });
  }

  render() {
    return (
      <div style={{ maxWidth: 1200, margin: '150px auto 100px auto' }}>
        <div className='wrapper'>
          <h1 style={{ textAlign: 'center' }}>404</h1>
          <p style={{ textAlign: 'center' }}>
            You lost? ;p
          </p>
        </div>
      </div>

    );
  }
}

const styles = {

}

export default NotFound;
