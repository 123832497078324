import React, { Component } from 'react';
import '../config/contact.css';
import axios from 'axios';
import { Button, Form } from 'semantic-ui-react'
// import history from '../history';
import { useNavigate } from 'react-router-dom';

const API_PATH = 'https://cannections.ca/dispensaryContact.php';

class ContactDispensary extends Component {

constructor(props) {
	super(props);
	this.state = {
  	dispensaryName: '',
  	name: '',
  	email: '',
  	message: ''
	}
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit(e){
	e.preventDefault();
	const navigate = useNavigate();
	this.setState({ sendingMail: true }, () => {
		axios({
			method: "POST", 
			url: `${API_PATH}`,
			data:  this.state
		  }).then((response)=>{
			if (response.data.status === 'success'){
			  this.setState({ sendingMail: false });
			  navigate('ContactDispensarySuccess');
			  //   alert("Message Sent."); 
			  this.resetForm()
			}else if(response.data.status === 'fail'){
			  this.setState({ sendingMail: false });
			  alert("Message failed to send.")
			}
		  })
	})
  }

  resetForm(){
     this.setState({dispensaryName: '', name: '', email: '', message: ''})
  }
  
  render() {
	return(
  	<div className="contactForm">
	  <h1 className='subscribeh1'>We cant wait to work with you</h1>
	  <p style={{ marginBottom: 50 }}>Please fill out this short form, we will get back to you within 24 hours!</p>

  	  <Form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
		<Form.Field>
		<Form.Input type="text" id="dispensaryName" value={this.state.dispensaryName} onChange={this.onDispensaryNameChange.bind(this)} label='Dispensary Name *' placeholder='Dispensary Name' />
		</Form.Field>
		<Form.Field>
		<Form.Input type="text" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} label='Your Name *' placeholder='Name' />
		</Form.Field>
		<Form.Field>
		<Form.Input type="email" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} label='Email *' placeholder='Email' />
		</Form.Field>
		<Form.Field>
		<Form.TextArea style={{ minHeight: 200 }} id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} label='Message' placeholder='Ask us anything... (optional)' />
		</Form.Field>
		<Button className='primaryBtn' style={{ marginTop: 15 }} type='submit' loading={this.state.sendingMail}>Request Demo</Button>
	</Form>
  	</div>
	);
  }

  onDispensaryNameChange(event) {
	this.setState({dispensaryName: event.target.value})
  }

  onNameChange(event) {
	this.setState({name: event.target.value})
  }

  onEmailChange(event) {
	this.setState({email: event.target.value})
  }

  onMessageChange(event) {
	this.setState({message: event.target.value})
  }
}

export default ContactDispensary;
