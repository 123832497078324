import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import firebase from 'firebase';
import colors from '../config/colors';
import axios from 'axios';
import history from '../history';

import {
  Button,
  Grid,
  Container,
  Image,
  Transition,
  Form,
} from 'semantic-ui-react';
import { ageChecked } from '../actions/authActions';

const API_PATH = 'https://cannections.ca/dispensaryContact.php';

class Dispensary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {
        name: '',
        email: '',
        url: '',
      },
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  sendCodeRequest = () => {
    const ts = Date.now();
    firebase
      .database()
      .ref('codeRequests')
      .child(ts)
      .set({
        name: this.state.name,
        email: this.state.email,
        url: this.state.url,
        desc: this.state.message,
      })
      .then(() => {
        this.setState({ requestSentSuccessfully: true });
        alert('Message Sent.');
        this.setState({
          sendingMail: false,
          formErrors: {
            name: null,
            email: null,
            url: null,
          },
        });
      })
      .catch(() => {
        this.setState({ requestSentSuccessfully: false });
        alert('Something went wrong, please try again.');
      });
  };

  handleSubmit(e) {
    const { name, email, url } = this.state;
    e.preventDefault();
    this.setState({ sendingMail: true }, () => {
      if (name && email && url) {
        this.sendCodeRequest();
      } else {
        this.setState({
          sendingMail: false,
          formErrors: {
            name: name ? false : true,
            email: email ? false : true,
            url: url ? false : true,
          },
        });
        // alert(
        //   'Unable to send this request. Please make sure all fields are complete.'
        // );
      }
    });
  }

  resetForm() {
    this.setState({ name: '', email: '', url: '', message: '' });
  }

  onnameChange(event) {
    this.setState({ name: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onWebsiteChange(event) {
    this.setState({ url: event.target.value });
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  renderForm = () => {
    return (
      <div className="contactForm">
        {/* <h1 className="subscribeh1">We cant wait to work with you</h1>
        <p style={{ marginBottom: 50 }}>
          Please fill out this short form, we will get back to you within 24
          hours!
        </p> */}
        <Form
          id="contact-form"
          onSubmit={this.handleSubmit.bind(this)}
          method="POST"
        >
          <Form.Field>
            <Form.Input
              type="text"
              id="name"
              value={this.state.name}
              onChange={this.onnameChange.bind(this)}
              label="Business Name *"
              placeholder="What is the name of your business?"
              error={
                this.state.formErrors.name
                  ? {
                    content: 'Please enter your business name.',
                  }
                  : null
              }
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              type="email"
              id="email"
              value={this.state.email}
              onChange={this.onEmailChange.bind(this)}
              label="Email *"
              placeholder="brand@email.com"
              error={
                this.state.formErrors.email
                  ? {
                    content: 'Please enter a email so we can reach you.',
                  }
                  : null
              }
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              type="url"
              id="url"
              aria-describedby="urlHelp"
              value={this.state.url}
              onChange={this.onWebsiteChange.bind(this)}
              label="Website / Social Url *"
              placeholder="https://website.com"
              error={
                this.state.formErrors.url
                  ? {
                    content: 'Please enter a URL.',
                  }
                  : null
              }
            />
          </Form.Field>
          <Form.Field>
            <Form.TextArea
              style={{ minHeight: 200 }}
              id="message"
              value={this.state.message}
              onChange={this.onMessageChange.bind(this)}
              label="Message"
              placeholder="Ask us anything... (optional)"
            />
          </Form.Field>
          <Button
            className="primaryBtn"
            style={{ marginTop: 15 }}
            // type="submit"
            loading={this.state.sendingMail}
          // onPress={() => {
          //   this.sendCodeRequest();
          // }}
          >
            Request a code
          </Button>
        </Form>
      </div>
    );
  };

  render() {
    return (
      <div style={{ width: '100%' }}>
        <Transition
          visible={true}
          animation="fade"
          transitionOnMount
          duration={750}
        >
          <div id="headerDispensary">
            <h1>
              <span>Level up</span> your shop
            </h1>
            <p>
              Connect with existing and incoming customers like never before
            </p>
            {/* <Link to="/contactDispensary" className="item"> */}
            {/* <Button size="large" className="ctaButtonHead">
              Request a code
            </Button> */}
            {/* </Link> */}

            {/* <div id='dispHeaderOverlay' /> */}
            {/* <Image src="../disp-header" />       */}
          </div>
        </Transition>

        <Container
          style={{ zIndex: 999999999999999999999, position: 'relative' }}
        >
          <Grid
            columns="equal"
            stackable
            className="wrapperShadowDispensary"
            style={{ marginBottom: 50 }}
          >
            <Grid.Row
              centered
              columns={2}
              style={{ display: 'flex', alignItems: 'flex-start' }}
            >
              <Grid.Column width={7}>
                <h2 style={{ marginTop: 10 }}>Is your dispensary or headshop eligible?</h2>
                <p style={{ marginBottom: 30 }}>
                  At Cannections, we carefully review each business request to ensure it aligns with our app's standards. Your business must be legitimate to join our platform.
                  <br />
                  <br />
                  Upon approval, you will receive an exclusive sign-up code via the email provided, granting you full access to a wide array of premium features, including:
                </p>
                <ul>
                  <li>
                    A customizable profile showcasing your address, business hours, product offerings, and more.
                  </li>
                  <li>
                    The ability to create product posts that highlight your offerings, complete with direct links to your product listings.
                  </li>
                  <li>Up to three captivating product photos per post.</li>
                  <li>Enhanced visibility through the option to boost your product posts.</li>
                  <li>Prominent placement on our Brand pages across our platforms.</li>
                  <li>Add strain inventories to your profile directly from the Strain Finder (contact us for more information).</li>
                  <li>And much more!</li>
                </ul>
                <p>
                  Our mission is to connect businesses with cannabis enthusiasts both locally and beyond.
                </p>
                <p>Don't miss out! It's time to get CANNECTED.</p>
              </Grid.Column>
              <Grid.Column>
                <div className="tabBoxAlt">{this.renderForm()}</div>
              </Grid.Column>
            </Grid.Row>

            {/* <Grid.Row className="titleRow">
              <div>
                <h2>Why choose us?</h2>
              </div>
            </Grid.Row> */}
            {/* 
            <Grid columns={2} divided id="whyChooseUs" stackable>
              <Grid.Row>
                <Grid.Column className="whyChooseUsBox">
                  <div className="circleIconWrap">
                    <i
                      class="far fa-rocket"
                      style={{ color: colors.skizzuGreen, fontSize: 42 }}
                    />
                  </div>
                  <div>
                    <h3 style={{ marginBottom: 15 }}>Seamless integration</h3>
                    <p>
                      The strains you need are already there for you, all you
                      have to do is add them to your inventory with pricing, and
                      labels if you so wish.
                    </p>
                  </div>
                </Grid.Column>
                <Grid.Column className="whyChooseUsBox">
                  <div className="circleIconWrap">
                    <i
                      class="far fa-database"
                      style={{ color: colors.skizzuGreen, fontSize: 42 }}
                    />
                  </div>
                  <div>
                    <h3 style={{ marginBottom: 15 }}>Real time</h3>
                    <p>
                      Change your menu in real-time. Any orders recieved from in
                      store or on the Cannections app will be on your end within
                      the blink of an eye.
                    </p>
                  </div>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column className="whyChooseUsBox">
                  <div className="circleIconWrap">
                    <i
                      class="far fa-palette"
                      style={{ color: colors.skizzuGreen, fontSize: 42 }}
                    />
                  </div>
                  <div>
                    <h3 style={{ marginBottom: 15 }}>
                      Customizable Look and Feel
                    </h3>
                    <p>
                      Emphasize your Company's look and feel with your colors
                      and logo.
                    </p>
                  </div>
                </Grid.Column>
                <Grid.Column className="whyChooseUsBox">
                  <div className="circleIconWrap">
                    <i
                      class="far fa-phone-laptop"
                      style={{ color: colors.skizzuGreen, fontSize: 42 }}
                    />
                  </div>
                  <div>
                    <h3 style={{ marginBottom: 15 }}>Cross Platform</h3>
                    <p>
                      Fix up your menu in one central place, for users to view
                      anywhere whether they're online, on our app, or in your
                      store! Seamlessely Maximize your Dispensaries' exposure!
                    </p>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid> */}
          </Grid>

          <Transition
            visible={true}
            animation="fade up"
            transitionOnMount
            duration={1000}
          >
            <div
              className="dispCircles"
              style={{
                width: 240,
                height: 240,
                borderRadius: 150,
                top: -100,
                right: -100,
                backgroundColor: colors.skizzuGreen,
                position: 'absolute',
                zIndex: -1,
              }}
            />
          </Transition>
          <Transition
            visible={true}
            animation="fade up"
            transitionOnMount
            duration={1200}
          >
            <div
              className="dispCircles"
              style={{
                width: 120,
                height: 120,
                borderRadius: 100,
                top: 350,
                left: -70,
                backgroundColor: colors.secondaryGreen,
                position: 'absolute',
                zIndex: -1,
              }}
            />
          </Transition>
        </Container>
      </div>
    );
  }
}

const styles = {
  ctaButtonOG: {
    //  maxWidth: 150,
    color: colors.white,
    backgroundColor: colors.skizzuGreen,
    borderRadius: 50,
  },
  ctaButton: {
    //  maxWidth: 150,
    color: colors.white,
    backgroundColor: colors.secondaryGreen,
    borderRadius: 50,
  },
};

function mapStateToProps(state) {
  return {
    isOfAge: state.isOfAge,
  };
}

export default connect(
  mapStateToProps,
  {
    ageChecked,
  }
)(Dispensary);
