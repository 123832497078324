
import {
  AGE_CHECKED
 } from './types';


 export const ageChecked = (boolean) => {
  return {
    type: AGE_CHECKED,
    payload: boolean
  };
};
