import React, { Component } from 'react';
import '../config/contact.css';
import axios from 'axios';
import { Button, Form } from 'semantic-ui-react'

const API_PATH = 'https://cannections.ca/contactCannections.php';

class Contact extends Component {

constructor(props) {
	super(props);
	this.state = {
  	name: '',
  	email: '',
  	message: ''
	}
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit(e){
	e.preventDefault();
	this.setState({ sendingMail: true }, () => {
		axios({
			method: "POST", 
			url: `${API_PATH}`,
			data:  this.state
		  }).then((response)=>{
			if (response.data.status === 'success'){
			  this.setState({ sendingMail: false })
			  alert("Message Sent."); 
			  this.resetForm()
			}else if(response.data.status === 'fail'){
			  this.setState({ sendingMail: false });
			  alert("Message failed to send.")
			}
		  })
	})
  }

  resetForm(){
    
     this.setState({name: '', email: '', message: ''})
  }
  
  render() {
	return(
  	<div className="contactForm" style={{ marginTop: 150}}>
	  <h1 className='subscribeh1'>Let's get in touch</h1>
	  <p style={{ marginBottom: 50 }}>How can we help you?</p>

  	  <Form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
		<Form.Field>
		<Form.Input type="text" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} label='Full Name' placeholder='Name' />
		</Form.Field>
		<Form.Field>
		<Form.Input type="email" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} label='Email' placeholder='Email' />
		</Form.Field>
		<Form.Field>
		<Form.TextArea style={{ minHeight: 200 }} id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} label='Message' placeholder='How can we help you?' />
		</Form.Field>
		<Button className='primaryBtn' style={{ marginTop: 15 }} type='submit' loading={this.state.sendingMail}>Submit</Button>
	</Form>
  	</div>
	);
  }

  onNameChange(event) {
	this.setState({name: event.target.value})
  }

  onEmailChange(event) {
	this.setState({email: event.target.value})
  }

  onMessageChange(event) {
	this.setState({message: event.target.value})
  }
}

export default Contact;
