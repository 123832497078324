import React, { Component } from 'react';
import { Grid, Image, Button, Segment, Dimmer, Loader } from 'semantic-ui-react'
import colors from '../../config/colors'
import moment from 'moment'

const styles = {
    blogContainer: {
        backgroundColor: colors.white,
        boxShadow: '0 0px 2px 0px rgba(0,0,0,.1)',
        padding: 20,
    },
    image: {
        backgroundColor: colors.borderLight,
        width: '100%',
    },
    ctaButton: {
       color: colors.white, 
       backgroundColor: colors.skizzuGreen, 
       borderRadius: 50,
     },
}

class BlogRecents extends Component {
  constructor(props) {
    super(props);

    this.state = {
        width: window.innerWidth,
        email: null,
        visibleMobile: false
      }
  }

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  render () {
    // console.log(this.props.posts)
    const posts = this.props.posts ? this.props.posts : []
    if (posts.length === 0) {
      return (
        <Segment style={{ width: '100%', height: 460, margin: 0 }}>
        <Dimmer active inverted
        >
          <Loader size='large'>Loading</Loader>
        </Dimmer>
      </Segment>
      )
    }
    return (
      <Grid stackable relaxed style={{ marginBottom: 10 }}>
      <Grid.Row columns={3}>
      {posts.map(item => (
        <Grid.Column>
        <Image src={item.featured_image_src.list[0]} style={styles.image}/>
          <div style={styles.blogContainer}>
              <h3>{item.title.rendered}</h3>
              <p id='smallParagraphDate'>{moment(item.date).format('MMMM Do YYYY')}</p>
              <a href={item.link} className="item">
                <Button size='small' style={styles.ctaButton}>Read More</Button>
              </a>
          </div>
        </Grid.Column>
      ))}
      
      </Grid.Row>
    </Grid>
  );
  }
}


export default BlogRecents;
