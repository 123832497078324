import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import colors from '../config/colors';
import _ from 'lodash';
import Magnifier from 'react-magnifier';
import moment from 'moment';

import {
  Segment,
  Grid,
  Container,
  Comment,
  Icon,
  Image,
  Header,
  Button,
} from 'semantic-ui-react';

import {
  reviewsFetch,
  imagesFetch,
  fetchStrainPostsCount,
  fetchReviewCount,
  strainPostCountUnmount,
  selectStrain,
} from '../actions/strainActions';

const StrainDetail = ({
}) => {
  const dispatch = useDispatch();
  const params = useParams();

  const strain = useSelector(state => state.selectedStrain);
  const reviews = useSelector(state => _.map(state.reviews, (val, uid) => ({ ...val, uid })));
  const images = useSelector(state => _.map(state.strainImages, (val, uid) => ({ ...val, uid })));
  const postCount = useSelector(state => state.strainPostCount);
  const reviewCount = useSelector(state => state.reviewCount);


  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.id !== strain.title) {
      dispatch(selectStrain(params.id, null));
      fetchData(params.id);
    }
    return () => {
      dispatch(strainPostCountUnmount());
    };
  }, [params.id, dispatch]);

  const fetchData = (id) => {
    dispatch(reviewsFetch(id));
    dispatch(imagesFetch(id));
    dispatch(fetchReviewCount(id));
    dispatch(fetchStrainPostsCount(id));
  };

  const renderImage = (img) => {
    const { Sativa, Indica } = strain;
    if (!img) {
      return (
        <Segment placeholder className="emptyData">
          <Header icon>
            <img
              className="ui small image"
              style={{ width: 40, opacity: 0.2, marginBottom: 15 }}
              src="../black-leaf.png"
              alt="Cannections Leaf"
            />
            <p style={{ padding: '10px 10px 0px 10px', fontSize: 12 }}>
              We have not captured {strain.title}{' '}
              yet, have you?
            </p>
          </Header>
          <a
            href="https://apps.apple.com/us/app/cannections/id1465185228"
            target="_blank"
            style={{
              color: Sativa
                ? colors.orange
                : Indica
                  ? colors.purple
                  : colors.skizzuGreen,
              textAlign: 'center'
            }}
          >
            Post yours now
          </a>
        </Segment>
      );
    } else {
      return (
        <Segment id="strainImageContainer">
          <Magnifier
            src={img}
            zoomFactor={3}
            id="strainImage"
            alt={strain.title}
          />
        </Segment>
      );
    }
  }

  const effects = () => {
    const { Sativa, Indica } = strain;
    const effects = strain.effects || [];
    let effectsArray = Object.keys(effects);
    effectsArray = JSON.parse(
      JSON.stringify(effectsArray).replace(/"\s+|\s+"/g, '"')
    );

    if (effectsArray.length === 0) {
      return <p>N/A</p>;
    } else if (typeof effects === 'string') {
      return (
        <Container style={{}}>
          <p
            className="effectMedText"
            style={{
              color: Sativa
                ? colors.orange
                : Indica
                  ? colors.purple
                  : colors.skizzuGreen,
            }}
          >
            {effectsArray}
          </p>
        </Container>
      );
    }
    return (
      <Container style={{}}>
        <p
          className="effectMedText"
          style={{
            color: Sativa
              ? colors.orange
              : Indica
                ? colors.purple
                : colors.skizzuGreen,
          }}
        >
          {effectsArray.join(', ').trim()}
        </p>
      </Container>
    );
  }

  const helpsWith = () => {
    const { Sativa, Indica } = strain;
    const medical = strain.medical || [];
    let medicalArray = Object.keys(medical);
    medicalArray = JSON.parse(
      JSON.stringify(medicalArray).replace(/"\s+|\s+"/g, '"')
    );

    if (medicalArray.length === 0) {
      return <p>N/A</p>;
    } else if (typeof medical === 'string') {
      return (
        <Container style={{}}>
          <p
            className="effectMedText"
            style={{
              color: Sativa
                ? colors.orange
                : Indica
                  ? colors.purple
                  : colors.skizzuGreen,
            }}
          >
            {medicalArray}
          </p>
        </Container>
      );
    }
    return (
      <Container style={{}}>
        <p
          className="effectMedText"
          style={{
            color: Sativa
              ? colors.orange
              : Indica
                ? colors.purple
                : colors.skizzuGreen,
          }}
        >
          {medicalArray.join(', ').trim()}
        </p>
      </Container>
    );
  }

  const renderFlavours = () => {
    const { Sativa, Indica } = strain;
    const flavors = strain.flavors || [];
    let flavorsArray = Object.keys(flavors);
    flavorsArray = JSON.parse(
      JSON.stringify(flavorsArray).replace(/"\s+|\s+"/g, '"')
    );

    if (flavorsArray.length === 0) {
      return <p>N/A</p>;
    } else if (typeof flavors === 'string') {
      return (
        <Container style={{}}>
          <p
            className="effectMedText"
            style={{
              color: Sativa
                ? colors.orange
                : Indica
                  ? colors.purple
                  : colors.skizzuGreen,
            }}
          >
            {flavorsArray}
          </p>
        </Container>
      );
    }
    return (
      <Container style={{}}>
        <p
          className="effectMedText"
          style={{
            color: Sativa
              ? colors.orange
              : Indica
                ? colors.purple
                : colors.skizzuGreen,
          }}
        >
          {flavorsArray.join(', ').trim()}
        </p>
      </Container>
    );
  }

  const renderAromas = () => {
    const { Sativa, Indica } = strain;
    const aromas = strain.aromas || [];
    let aromasArray = Object.keys(aromas);
    aromasArray = JSON.parse(
      JSON.stringify(aromasArray).replace(/"\s+|\s+"/g, '"')
    );

    if (aromasArray.length === 0) {
      return <p>N/A</p>;
    } else if (typeof aromas === 'string') {
      return (
        <Container style={{}}>
          <p
            className="effectMedText"
            style={{
              color: Sativa
                ? colors.orange
                : Indica
                  ? colors.purple
                  : colors.skizzuGreen,
            }}
          >
            {aromasArray}
          </p>
        </Container>
      );
    }
    return (
      <Container style={{}}>
        <p
          className="effectMedText"
          style={{
            color: Sativa
              ? colors.orange
              : Indica
                ? colors.purple
                : colors.skizzuGreen,
          }}
        >
          {aromasArray.join(', ').trim()}
        </p>
      </Container>
    );
  }

  const renderTHC = () => {
    const { thc } = strain;
    if (thc) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: 0,
          }}
        >
          <i
            className={'fas fa-hexagon'}
            style={{ color: colors.yellow, fontSize: 16 }}
          />
          <h3 style={{ marginRight: 16 }}>THC: {thc}</h3>
        </div>
      );
    } else {
      return null;
    }
  }
  const renderCDB = () => {
    const { cbd } = strain;
    if (cbd) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: 0,
          }}
        >
          <i
            className={'fas fa-circle'}
            style={{ color: colors.blue, fontSize: 16 }}
          />
          <h3 style={{ marginRight: 16 }}>CBD: {cbd}</h3>
        </div>
      );
    } else {
      return null;
    }
  }

  const renderDescription = () => {
    if (strain.description) {
      return (
        <div>
          <h3>Description</h3>
          <p>{strain.description}</p>
        </div>
      );
    } else {
      return null;
    }
  }

  const renderTerps = () => {
    if (strain.terps) {
      return (
        <div style={{ marginBottom: 20 }}>
          <h3>Terps</h3>
          <p>{strain.terps}</p>
        </div>
      );
    } else {
      return null;
    }
  }

  const renderBrand = () => {
    if (strain.shop) {
      return (
        <div>
          <h3 style={{ marginTop: 20 }}>Brand</h3>
          <p>{strain.shop}</p>
        </div>
      );
    } else {
      return null;
    }
  }

  const renderReviews = () => {
    if (reviews.length) {
      return (
        <div>
          <h2>Reviews</h2>
          <Segment>
            <h3>See what people are saying about {strain.title}</h3>
            <ul style={{ paddingLeft: 10, paddingTop: 10 }}>
              {reviews.map((review) => {
                return (
                  <Comment.Group key={review.ts}>
                    <Comment>
                      <Comment.Avatar as="a" src={review.avy} />
                      <Comment.Content>
                        <Comment.Author>{review.user}</Comment.Author>
                        <Comment.Metadata
                          style={{ marginLeft: 0, marginTop: 5 }}
                        >
                          <div>{moment(review.ts).format('MMMM Do YYYY')}</div>
                          {review.l ? (
                            <div style={{ marginLeft: 4 }}>
                              <Icon name="heart" />
                              {review.l}
                            </div>
                          ) : null}
                        </Comment.Metadata>
                        <Comment.Text>{review.r}</Comment.Text>
                      </Comment.Content>
                    </Comment>
                  </Comment.Group>
                );
              })}
            </ul>
          </Segment>
        </div>
      );
    } else {
      return (
        <div>
          <h2>Reviews</h2>
          <Segment placeholder style={{ minHeight: 0 }}>
            <h3>There aren't any reviews yet for this strain...</h3>
            <p>Be the first to leave one, download the app to get started!</p>
          </Segment>
        </div>
      );
    }
  }

  const strainPosts = () => {
    if (images.length) {
      return (
        <div>
          <h2>User posts</h2>
          <Segment>
            <Grid id="strainImageGrid">
              <Grid.Row columns={3}>
                {images.map((image) => {
                  return (
                    <Grid.Column>
                      <Image src={image.t} id="strainGridImage" />
                    </Grid.Column>
                  );
                })}
              </Grid.Row>
            </Grid>
          </Segment>
        </div>
      );
    } else {
      return (
        <div>
          <h2>Posts</h2>
          <Segment placeholder style={{ minHeight: 0 }}>
            <h3>No posts available</h3>
            <p>
              Let's see what you got. Download the app to post your{' '}
              {strain.title}
            </p>
          </Segment>
        </div>
      );
    }
  }


  // Render logic
  const { title, description, Hybrid, Sativa, Indica, thc, cbd, img } = strain;

  // Check if the strain is null before rendering
  if (!strain) {
    return <div>Loading...</div>; // or any other placeholder you'd like to show
  }

  return (
    <div>
      <div
        className="middle aligned"
        id="headerStain"
        // style={{ backgroundColor: Sativa ? colors.orange : Indica ? colors.purple : colors.skizzuGreen}}
        style={{ backgroundColor: colors.white }}
      >
        <h1 style={{ marginBottom: 20 }}>{title}</h1>
        <Grid centered columns={4} id="strainDataCountContainer">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Icon name="heart" />
            <h3>{strain.likeCount || 0} Likes</h3>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Icon name="comments" />
            <h3>{reviewCount || 0} Reviews</h3>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Icon name="images" />
            <h3>{postCount || 0} Images</h3>
          </div>
        </Grid>
      </div>

      <Container
        style={{ zIndex: 999999999999999999999, position: 'relative' }}
      >
        <Grid columns="equal" stackable id="strainContain">
          <Grid.Column id="stainLeftContain" width={5}>
            <h2>Budshot</h2>
            {renderImage(img)}
            {strainPosts()}
          </Grid.Column>

          <Grid.Column id="stainRightContain">
            <h2>Details</h2>
            <Segment>
              {/* <h3>Type and Levels</h3> */}
              <div id="strainValuesContainer">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingLeft: 0,
                  }}
                >
                  <i
                    className={`fas ${Sativa ? 'fa-rocket' : Indica ? 'fa-bed' : 'fa-bolt'
                      }`}
                    style={{
                      color: Sativa
                        ? colors.orange
                        : Indica
                          ? colors.purple
                          : colors.skizzuGreen,
                      fontSize: 16,
                    }}
                  />{' '}
                  <h3>{Sativa ? 'Sativa' : Indica ? 'Indica' : 'Hybrid'}</h3>
                </div>
                {renderTHC()}
                {renderCDB()}
              </div>
              {renderTerps()}
              {renderDescription()}
              {renderBrand()}
              {strain.effects ? <div style={{ marginTop: 20 }}>
                <h3>Effects</h3>
                {effects()}
              </div> : null}

              {strain.medical ? <div style={{ marginTop: 20 }}>
                <h3>Medical benifits</h3>
                {helpsWith()}
              </div> : null}

              {strain.flavors ? <div style={{ marginTop: 20 }}>
                <h3>Flavors</h3>
                {renderFlavours()}
              </div> : null}

              {strain.aromas ? <div style={{ marginTop: 20 }}>
                <h3>Aromas</h3>
                {renderAromas()}
              </div> : null}

            </Segment>
            {renderReviews()}
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );

};



export default StrainDetail;
