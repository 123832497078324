import React, { Component } from 'react';
import '../config/contact.css';
import axios from 'axios';
import { Button, Form } from 'semantic-ui-react'

const API_PATH = 'https://cannections.ca/subscribeCannections.php';

class Subscribe extends Component {
	
constructor(props) {
	super(props);
	this.state = {
  	email: '',
	}
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit(e){
	e.preventDefault();
	this.setState({ sendingMail: true }, () => {
		axios({
			method: "POST", 
			url: `${API_PATH}`,
			data:  this.state
		  }).then((response)=>{
			if (response.data.status === 'success'){
				this.setState({ sendingMail: false })
			  alert("Message Sent."); 
			  this.resetForm()
			}else if(response.data.status === 'fail'){
			  this.setState({ sendingMail: false });
			  alert("Message failed to send.")
			}
		  })
	})
  }

  resetForm(){
    
     this.setState({name: '', email: '', message: ''})
  }
  
  render() {
	return(
  	<div className="contactForm">
	  <h1 className='subscribeh1'>Prepare to get Cannected</h1>
	  <p style={{ marginBottom: 50 }}>We cant wait to have you join the Cannections Community. We will notify you via the email you provide as soon as we take off!</p>
	  
  	  <Form id="subscribe-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
		<Form.Field>
		<Form.Input type="email" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} label='Email' placeholder='Enter your email' />
		</Form.Field>
		<Button className='primaryBtn' style={{ marginTop: 15 }} type='submit' loading={this.state.sendingMail}>Submit</Button>
	</Form>
  	</div>
	);
  }

  onEmailChange(event) {
	this.setState({email: event.target.value})
  }
}

export default Subscribe;
