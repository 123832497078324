// import React, { Component } from 'react';
// import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './reducers';

    const persistConfig = {
      key: 'root',
      storage,
      // whitelist: ['strains, selectedStrain'],
      blacklist: ['searchedStrain']
    };

    const persistedReducer = persistReducer(persistConfig, reducers);

    // const store = createStore(persistedReducer, {}, (applyMiddleware(ReduxThunk)));
    const store = createStore(persistedReducer, {}, composeWithDevTools(applyMiddleware(ReduxThunk)));
    const persistor = persistStore(store);

    export { store, persistor };
    