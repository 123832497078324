import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalPopup } from './common/Modal';
import colors from '../config/colors';
import {
  Button,
  Grid,
  Container,
  Image,
  Transition
} from 'semantic-ui-react';
import { ageChecked } from '../actions/authActions';

class Dispensary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {    
    return (
      <div style={{ width: '100%'}}>
          <Transition visible={true} animation='fade' transitionOnMount duration={750}>
            <div id="headerDispensary">
                <h1><span>Level up</span> your Dispensary</h1>          
                <p>With our real-time, cross-platform software</p>   
                <Link to="/contactDispensary" className="item">
                      <Button size='large' className="ctaButtonHead">Request Demo</Button>
                </Link> 
                    
                {/* <div id='dispHeaderOverlay' /> */}
                {/* <Image src="../disp-header" />       */}
            </div>
          </Transition>

        <Container style={{ zIndex: 999999999999999999999, position: 'relative' }}>

          <Grid columns='equal' stackable className='wrapperShadowDispensary' style={{ marginBottom: 50 }}>

            <Grid.Row centered columns={2} style={{display: 'flex', alignItems: 'center'}}>
              <Grid.Column>
                <Image src='../tester2.png' style={{ borderRadius: 4, width: 1800, padding: 50 }} />
              </Grid.Column>
              <Grid.Column width={8}>
              <h2>We have you covered</h2>
                <p style={{ marginBottom: 30 }}>
                 Our dispensary services are built to ensure we give our customers (and your customers) the best cannabis shopping experience possible. Our modern, efficient and attractive user interface is designed to provide you with seamless inventory management which is projected in real-time to your in store displays and profile menus on our social app.
                </p>
                <Link to="/contactDispensary" className="item">
                    <Button size='large' style={styles.ctaButtonOG}>Request Demo</Button>
                  </Link>
              </Grid.Column>
              
            </Grid.Row>

             {/* <Grid.Row className='titleRowLeft'> */}
               {/* <div> */}
                 {/* <h1>Why choose us?</h1> */}
                 {/* <hr style={{ width: 80, height: 2.5, borderRadius: 30, marginTop: 20, marginBottom: -10 }}/> */}
               {/* </div> */}
            {/* </Grid.Row>  */}

            <Grid.Row className='titleRow'>
               <div>
                 <h2>Why choose us?</h2>
                 {/* <hr style={{ width: 80, height: 2.5, borderRadius: 30, marginTop: 20, marginBottom: -10 }}/> */}
               </div>
            </Grid.Row> 

            

            <Grid columns={2} divided id="whyChooseUs" stackable>
              <Grid.Row>
                <Grid.Column className="whyChooseUsBox">
                  <div className='circleIconWrap'>
                     <i class='far fa-rocket' style={{ color: colors.skizzuGreen, fontSize: 42 }} /> 
                  </div>
                <div>
                    <h3 style={{ marginBottom: 15 }}>Seamless integration</h3>          
                    <p>The strains you need are already there for you, all you have to do is add them to your inventory with pricing, and labels if you so wish.</p> 
                  </div>         
                </Grid.Column>
              <Grid.Column className="whyChooseUsBox">
                  <div className='circleIconWrap'>
                     <i class='far fa-database' style={{ color: colors.skizzuGreen, fontSize: 42 }} /> 
                  </div>
                <div>
                    <h3 style={{ marginBottom: 15 }}>Real time</h3>          
                    <p>Change your menu in real-time. Any orders recieved from in store or on the Cannections app will be on your end within the blink of an eye.</p> 
                  </div>    
              </Grid.Column>
              </Grid.Row>

              <Grid.Row>
              <Grid.Column className="whyChooseUsBox">
                  <div className='circleIconWrap'>
                     <i class='far fa-palette' style={{ color: colors.skizzuGreen, fontSize: 42 }} /> 
                  </div>
                  <div>
                    <h3 style={{ marginBottom: 15 }}>Customizable Look and Feel</h3>          
                    <p>Emphasize your Company's look and feel with your colors and logo.</p> 
                  </div>    
              </Grid.Column>
              <Grid.Column className="whyChooseUsBox">
                  <div className='circleIconWrap'>
                     <i class='far fa-phone-laptop' style={{ color: colors.skizzuGreen, fontSize: 42 }} /> 
                  </div>
                <div>
                    <h3 style={{ marginBottom: 15 }}>Cross Platform</h3>          
                    <p>Fix up your menu in one central place, for users to view anywhere whether they're online, on our app, or in your store! Seamlessely Maximize your Dispensaries' exposure!</p> 
                  </div>    
              </Grid.Column>
              </Grid.Row>

              {/* <Grid.Row>
              <Grid.Column className="whyChooseUsBox">
                  <div className='circleIconWrap'>
                     <i class='far fa-cannabis' style={{ color: colors.skizzuGreen, fontSize: 42 }} /> 
                  </div>
                <div>
                    <h2>Customizable Look and Feel</h2>          
                    <p>Emphasize your Company's look and feel with your colors and logo.</p> 
                  </div>    
              </Grid.Column>
              <Grid.Column className="whyChooseUsBox">
                  <div className='circleIconWrap'>
                     <i class='far fa-cannabis' style={{ color: colors.skizzuGreen, fontSize: 42 }} /> 
                  </div>
                <div>
                    <h2>Cross Platform</h2>          
                    <p>Fix up your menu in one central place, for users to view anywhere whether they're online, on our app, or in your store! Seamlessely Maximize your Dispensaries' exposure!</p> 
                  </div>    
              </Grid.Column>
              </Grid.Row> */}
            </Grid>



            <Grid.Row className='titleRow'>
               <div>
                 <h2>Our software is easy as 1, 2, 3!</h2>
                 {/* <hr style={{ width: 80, height: 2.5, borderRadius: 30, marginTop: 20, marginBottom: -10 }}/> */}
               </div>
            </Grid.Row> 

            <Grid.Row className='tabBoxes' columns={3} style={{}}>
              <Grid.Column>
                  <div className='tabBoxAlt'>
                      <div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          {/* <i class='far fa-cannabis' style={{ color: colors.skizzuGreen, fontSize: 20 }} />  */}
                            <h3>Menu</h3>
                        </div>
                        <Image src="../home-horizontal.png" style={{ width: '100%', paddingTop: 15, }} />
                        <p>
                          Showcase all your strains on your new menu interface. Introducing a flexible, customizable and fast experience designed to help users find what they need quickly.
                          
                        </p>
                      </div>
                      {/* <Link to="/Strains" className="item">
                        <Button size='small' style={styles.Head}>Try the Web Beta</Button>
                       </Link> */}
                  </div>
              </Grid.Column>
              <Grid.Column>
                  <div className='tabBoxAlt'>
                      <div>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {/* <i 
                            class='far fa-balance-scale-left'
                            style={{ color: colors.skizzuGreen, fontSize: 20 }}
                          />  */}
                          <h3>Strain Details</h3>
                      </div>
                      <Image src="../details-horizontal.png" style={{ width: '100%', paddingTop: 15, }} />

                      <p>
                          Ensure users are getting what they asked for. Each strain is populated with accurate data allowing users to quickly scan and pick the right bud for them. 
                      </p>
                      </div>
                      {/* <Link to="/contactDispensary" className="item">
                        <Button size='small' style={styles.ctaButton}>Get the App</Button>
                       </Link> */}
                  </div>
              </Grid.Column>
              <Grid.Column>
                  <div className='tabBoxAlt'>
                      <div>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {/* <i 
                            class='far fa-list'
                            style={{ color: colors.skizzuGreen, fontSize: 20 }}
                          />  */}
                          <h3>Order Summary</h3>
                      </div>
                      <Image src="../order-horizontal.png" style={{ width: '100%', paddingTop: 15, }} />
                      <p>
                        Before checking out, users can view their bag and adjust amounts to their liking. Once they're satisfied, their order is ready for your team to cash out! Get ahead and prioritize orders as they come.
                      </p>
                      </div>
                      {/* <Link to="/contactDispensary" className="item">
                        <Button size='small' style={styles.ctaButton}>Get the App</Button>
                       </Link> */}
                  </div>
              </Grid.Column>
            </Grid.Row>



            <Grid.Row centered columns={2} style={{display: 'flex', alignItems: 'center', marginTop: 30 }}>
              <Grid.Column width={7}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>

                  <h2>Beautiful design, better experience</h2>
                   </div>
                  <p style={{ marginBottom: 40 }}>
                      Be at ease knowing your customers can navigate through your inventory with an interface designed to select their desired strains with a flawless user experience whether it's in your store, or in the comfort of their home. 
                  </p>
                  <Link to="/contactDispensary" className="item">
                    <Button size='large' style={styles.ctaButtonOG}>Request Demo</Button>
                  </Link>

                </Grid.Column>
                <Grid.Column>
                  {/* <Image src='../get-budding-h3PWUqxpP_0-unsplash.jpg' style={{ borderRadius: 4 }}/> */}
                  <Image src='../tester3.png' style={{ borderRadius: 4, width: 1800, padding: 50 }} />
                </Grid.Column>
              </Grid.Row>



              <Grid.Row centered columns={2} style={{display: 'flex', alignItems: 'center', margin: '7em 0 3em 0'}}>
                <Grid.Column width={6}>
                    <Image src="../disp-mobile-prev.png" style={{ width: '90%', margin: '0 5% 1em 5%' }} />
                  </Grid.Column>
                <Grid.Column width={8}>
                <h2>Manage your inventory at your fingertips</h2>
                  <p>
                      Control your interfaces look, your menu, manage your orders, and add or remove strains at ease and in real time right from your phone. We make it so easy for you to show your customers what they want to see, and what you want them to see.
                      <br></br>
                      <br></br>
                      What are you waiting for? Request a demo and be up in running in no time!
                  </p>
                  <Link to="/contactDispensary" className="item">
                    <Button size='large' style={styles.ctaButtonOG}>Request Demo</Button>
                  </Link>
                </Grid.Column>
            </Grid.Row>

          </Grid>

          <Transition visible={true} animation='fade up' transitionOnMount duration={1000}>
              <div className='dispCircles' style={{ width: 240, height: 240, borderRadius: 150, top: -100, right: -100, backgroundColor: colors.skizzuGreen, position: 'absolute', zIndex: -1 }}/>
          </Transition>
          <Transition visible={true} animation='fade up' transitionOnMount duration={1200}>
              <div className='dispCircles' style={{ width: 120, height: 120, borderRadius: 100, top: 350, left: -70, backgroundColor: colors.secondaryGreen, position: 'absolute', zIndex: -1  }}/>
              </Transition>
          <div className='dispCircles' style={{ width: 220, height: 220, borderRadius: 200, top:1500, right: -100, backgroundColor: colors.blue, position: 'absolute', zIndex: -1 }}/>
          <div className='dispCircles' style={{ width: 310, height: 310, borderRadius: 200, top:2000, left: -120, backgroundColor: colors.orange, position: 'absolute', zIndex: -1 }}/>
          <div className='dispCircles' style={{ width: 245, height: 245, borderRadius: 200, top:2650, right: -90, backgroundColor: colors.secondaryGreen, position: 'absolute', zIndex: -1 }}/>

        </Container>
      </div>

    );
  }
}

const styles = {
 ctaButtonOG: {
  //  maxWidth: 150, 
   color: colors.white, 
   backgroundColor: colors.skizzuGreen, 
   borderRadius: 50,
 },
 ctaButton: {
  //  maxWidth: 150, 
   color: colors.white, 
   backgroundColor: colors.secondaryGreen, 
   borderRadius: 50,
 },
}

function mapStateToProps(state){
  return {
    isOfAge: state.isOfAge
  }
}


export default connect(mapStateToProps, {
  ageChecked,
})(Dispensary);