import firebase from 'firebase';
import history from '../history';
import _ from 'lodash';

import {
  STRAINS_FETCH_SUCCESS,
  STRAIN_SELECTED,
  SEARCH_STRAINS,
  REVIEWS_FETCH_SUCCESS,
  STRAIN_FILTER_TYPE,
  STRAIN_FILTER_EFFECT,
  IMAGES_FETCH_SUCCESS,
  REVIEW_COUNT,
  STRAIN_POST_COUNT,
  STRAIN_POST_COUNT_UNMOUNT,
  STRAIN_UNMOUNT
} from './types';

//key magic
function dataToArray(snapshot) {
  const returnArr = [];
  snapshot.forEach((childSnapshot) => {
    const item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });
  return returnArr.sort();
}

export const fetchStrains = (currentStrains, lastKey) => {
  if (!lastKey) {
    return (dispatch) => {
      firebase
        .database()
        .ref('/strainsMin')
        .orderByKey()
        .limitToFirst(15)
        .once('value', (snapshot) => {
          const strainArray = dataToArray(snapshot);
          const createLastKey = strainArray[strainArray.length - 1].key;
          dispatch({
            type: STRAINS_FETCH_SUCCESS,
            payload: strainArray,
            key: createLastKey,
          });
        });
    };
  }
  return (dispatch) => {
    firebase
      .database()
      .ref('/strainsMin')
      // .orderByKey()
      // .startAt(`${lastKey}`)
      .orderByKey()
      .startAfter(lastKey)
      .limitToFirst(16)
      .once('value', (snapshot) => {
        const strainArray = dataToArray(snapshot);
        if (strainArray.length === 0) {
          dispatch({
            type: STRAINS_FETCH_SUCCESS,
            payload: currentStrains,
            key: undefined,
          });
          return null;
        } else {
          const createLastKey = strainArray[strainArray.length - 1].key;
          const concatStrains = _.concat(currentStrains, strainArray);
          dispatch({
            type: STRAINS_FETCH_SUCCESS,
            payload: concatStrains,
            key: createLastKey,
          });
        }
      });
  };
};

export const filterByType = (type, currentStrains, lastKeyType) => {
  // alert(type)
  if (!lastKeyType) {
    return (dispatch) => {
      firebase
        .database()
        .ref('/strainsMin')
        .orderByChild(type)
        .startAt(true)
        .limitToFirst(15)
        .once('value', (snapshot) => {
          const strainArray = dataToArray(snapshot);
          const createLastKey = strainArray[strainArray.length - 1].key;
          dispatch({
            type: STRAIN_FILTER_TYPE,
            payload: strainArray,
            key: createLastKey,
          });
        });
    };
  }
  return (dispatch) => {
    firebase
      .database()
      .ref('/strainsMin')
      .orderByChild(type)
      .startAfter(true, `${lastKeyType}`)
      .limitToFirst(16)
      .once('value', (snapshot) => {
        const strainArray = dataToArray(snapshot);
        if (strainArray.length === 0) {
          dispatch({
            type: STRAIN_FILTER_TYPE,
            payload: currentStrains,
            key: undefined,
          });
          return null;
        } else {
          const createLastKey = strainArray[strainArray.length - 1].key;
          const concatStrains = _.concat(currentStrains, strainArray);
          dispatch({
            type: STRAIN_FILTER_TYPE,
            payload: concatStrains,
            key: createLastKey,
          });
        }
      });
  };
};
export const filterByEffect = (effectVal, currentStrains, lastKeyEffects) => {
  if (!lastKeyEffects) {
    return (dispatch) => {
      firebase
        .database()
        .ref('/strainsEffects')
        .orderByChild(`effects/${effectVal}`)
        .startAt(true)
        .limitToFirst(15)
        .once('value', (snapshot) => {
          const strainArray = dataToArray(snapshot);
          const createLastKey = strainArray[strainArray.length - 1].key;
          dispatch({
            type: STRAIN_FILTER_EFFECT,
            payload: strainArray,
            key: createLastKey,
          });
        });
    };
  }
  return (dispatch) => {
    firebase
      .database()
      .ref('/strainsEffects')
      .orderByChild(`effects/${effectVal}`)
      .startAfter(true, `${lastKeyEffects}`)
      .limitToFirst(16)
      .once('value', (snapshot) => {
        const strainArray = dataToArray(snapshot);
        if (strainArray.length === 0) {
          dispatch({
            type: STRAIN_FILTER_EFFECT,
            payload: currentStrains,
            key: undefined,
          });
          return null;
        } else {
          const createLastKey = strainArray[strainArray.length - 1].key;
          const concatStrains = _.concat(currentStrains, strainArray);
          dispatch({
            type: STRAIN_FILTER_EFFECT,
            payload: concatStrains,
            key: createLastKey,
          });
        }
      });
  };
};

export const filterByMedical = (medicalVal, currentStrains, lastKeyEffects) => {
  if (!lastKeyEffects) {
    return (dispatch) => {
      firebase
        .database()
        .ref('/strainsMedical')
        .orderByChild(`medical/${medicalVal}`)
        .startAt(true)
        .limitToFirst(15)
        .once('value', (snapshot) => {
          const strainArray = dataToArray(snapshot);
          const createLastKey = strainArray[strainArray.length - 1].key;
          dispatch({
            type: STRAIN_FILTER_EFFECT,
            payload: strainArray,
            key: createLastKey,
          });
        });
    };
  }
  return (dispatch) => {
    firebase
      .database()
      .ref('/strainsMedical')
      .orderByChild(`medical/${medicalVal}`)
      .startAfter(true, `${lastKeyEffects}`)
      .limitToFirst(16)
      .once('value', (snapshot) => {
        const strainArray = dataToArray(snapshot);
        if (strainArray.length === 0) {
          dispatch({
            type: STRAIN_FILTER_EFFECT,
            payload: currentStrains,
            key: undefined,
          });
          return null;
        } else {
          const createLastKey = strainArray[strainArray.length - 1].key;
          const concatStrains = _.concat(currentStrains, strainArray);
          dispatch({
            type: STRAIN_FILTER_EFFECT,
            payload: concatStrains,
            key: createLastKey,
          });
        }
      });
  };
};

export const searchStrains = (text) => {
  // const trimSearch = text.trim();
  return (dispatch) => {
    firebase
      .database()
      .ref('/strainsMin')
      .orderByChild('titleLowercase')
      .startAt(text)
      .endAt(`${text}\uf8ff`)
      .limitToFirst(10)
      .once('value', (snapshot) => {
        const strainArray = dataToArray(snapshot);
        dispatch({ type: SEARCH_STRAINS, payload: strainArray });
      });
  };
};


export const selectStrain = (strain, navigate) => {

  return (dispatch) => {
    firebase
      .database()
      .ref('/strains/')
      .orderByChild('titleLowercase')
      .equalTo(strain.toLowerCase())
      .once('value', (snapshot) => {
        if (!snapshot.exists()) {
          alert('Something went wrong.')
          return null;
        }
        // Get the first property value (the nested object)
        const data = Object.values(snapshot.val())[0];
        dispatch({ type: STRAIN_SELECTED, payload: data });
        if (navigate) {
          navigate('/strains/' + strain);
        }
      });
  };
};


export const reviewsFetch = (strain) => {
  return (dispatch) => {
    firebase
      .database()
      .ref('reviews/')
      .orderByChild('s')
      .equalTo(strain)
      .once('value', (snapshot) => {
        dispatch({ type: REVIEWS_FETCH_SUCCESS, payload: snapshot.val() });
      });
  };
};

export const imagesFetch = (strainId) => {
  return (dispatch) => {
    firebase
      .database()
      .ref('categoriesMin/')
      .orderByChild('s')
      .equalTo(strainId)
      .on('value', (snapshot) => {
        dispatch({ type: IMAGES_FETCH_SUCCESS, payload: snapshot.val() });
      });
  };
};

export const fetchReviewCount = (strainId) => {
  return (dispatch) => {
    firebase
      .database()
      .ref('reviewCount/')
      .child(strainId)
      .once('value', (snapshot) => {
        dispatch({ type: REVIEW_COUNT, payload: snapshot.val() });
      });
  };
};
export const fetchStrainPostsCount = (strainId) => {
  return (dispatch) => {
    firebase
      .database()
      .ref('strainPostCount/')
      .child(strainId)
      .once('value', (snapshot) => {
        dispatch({ type: STRAIN_POST_COUNT, payload: snapshot.val() });
      });
  };
};

export const strainPostCountUnmount = () => {
  return (dispatch) => {
    dispatch({ type: STRAIN_POST_COUNT_UNMOUNT });
  };
};

export const unmountStrains = () => {
  return dispatch => {
    dispatch({ type: STRAIN_UNMOUNT, payload: null });
  };
};
