import {
    STRAIN_POST_COUNT,
    STRAIN_POST_COUNT_UNMOUNT
  } from '../actions/types';
  //state argument is not application state, only the state the reducer is responsible for
  export default function (state = '', action) {
    switch (action.type) {
      case STRAIN_POST_COUNT:
        return action.payload;
      case STRAIN_POST_COUNT_UNMOUNT:
        return null;
        default:
    }
    return state;
  }
  