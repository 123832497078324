import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import colors from '../config/colors';
import firebase from 'firebase';
// import { Redirect } from 'react-router'
import {
  Segment,
  Transition
} from 'semantic-ui-react';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
        width: window.innerWidth,
        email: null,
        visibleMobile: false
      }
  }

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  // componentDidMount() {
  //   firebase.auth().onAuthStateChanged((user) => {
  //        if (user) {
  //            // this.props.userProfileInformationFetch();
  //            this.setState({ email: user.email });
  //        } else {
  //            this.setState({ email: null });
  //        }
  //    });
  // }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  
  

  handleScroll = (event) => {
    this.setState({
        scrollY: window.scrollY
    });
}

socialLinks(mobileMenu) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
        <a href="https://www.instagram.com/cannections_app/" target="blank" className="item" style={{ paddingRight: 5 }}>
          <i className={'fab fa-instagram'} 
            style={{ color: colors.skizzuGreen, fontSize: mobileMenu ? 28 : 22, marginRight: mobileMenu ? 10 : 0 }}>
        </i> 
        </a>
        <a href="https://www.facebook.com/cannections" target="blank" className="item" style={{ paddingRight: 5 }}>
        <i className={'fab fa-facebook'} 
            style={{ color: colors.skizzuGreen, fontSize: mobileMenu ? 28 : 22, marginRight: mobileMenu ? 10 : 0 }}>
        </i> 
        </a>
        <a href="https://twitter.com/cannections420" target="blank" className="item" style={{ paddingRight: 15 }}>
        <i className={'fab fa-twitter'} 
            style={{ color: colors.skizzuGreen, fontSize: mobileMenu ? 28 : 22, marginRight: mobileMenu ? 10 : 0 }}>
        </i> 
        </a>
    </div>
  );
}
  

renderRightActions() {
  // if (!this.state.email) {
    return (
      <div className="right menu">
          <NavLink to="/" exact className="item textLi">
            Home
          </NavLink>

          <NavLink to="/strains" className="item textLi">
            Strain Finder Beta
          </NavLink>

          <NavLink to="/brand" className="item textLi">
            Sign up your shop
          </NavLink>

          {/* <NavLink to="/dispensary" className="item textLi">
            Dispensary Services
          </NavLink> */}

          {/* <a href="https://cannections.ca/blog" exact className="item textLi" style={{ marginRight: 0 }}>
            Blog
          </a> */}

          <a href="https://apps.apple.com/us/app/cannections/id1465185228" className="item textLi" id='navLinkDownloadApp' style={{ marginRight: 10, marginTop:8 }}>
            Download App
          </a>
          {/* <NavLink to="/subscribe" exact className="item textLi" id='navLinkDownloadApp' style={{ marginRight: 10 }}>
            Download App
          </NavLink> */}

          {this.socialLinks()}
      </div>
    );
  } 
  // else {
  //   return null;
  // }
// }

renderMobileMenu() {
  const { width } = this.state;
  const isMobile = width <= 500;
  // if (this.state.visibleMobile) {
    return(
        <div style={{ backgroundColor: '#fff'}}>
          <Transition visible={this.state.visibleMobile} animation='slide right' duration={300}>
            <div style={{ top: 72, backgroundColor: '#fff', padding: 20, position: 'fixed', width: '80%', height: '100%', zIndex: 999999999999 }}>
            {/* <div style={{ top: this.state.scrollY > 250 ? 72 : 45, backgroundColor: '#fff', padding: 20, position: this.state.scrollY > 250 ? 'fixed' : 'relative', width: '80%', zIndex: this.state.scrollY > 250 ? 999999999999 : null }}> */}
              <Link to="/" className="item" onClick={() => this.setState({ visibleMobile: false})}>
                <Segment style={{ fontSize: 18, padding: 20, margin: '0 0 10px 0', backgroundColor: '#fff', color: '#212121', fontWeight: 300 }} >Home
                </Segment>
              </Link>

              {/* <Link to="/strains" className="item" onClick={() => this.setState({ visibleMobile: false})}>
                <Segment style={{ fontSize: 18, padding: 20, margin: '0 0 10px 0', backgroundColor: '#fff', color: '#212121', fontWeight: 300 }} >Strain Finder Beta</Segment>
              </Link> */}

              {/* <Link to="/dispensary" className="item" onClick={() => this.setState({ visibleMobile: false})}>
                <Segment style={{ fontSize: 18, padding: 20, margin: '0 0 10px 0', backgroundColor: '#fff', color: '#212121', fontWeight: 300 }} >Dispensary Services</Segment>
              </Link> */}

              <a href="https://apps.apple.com/us/app/cannections/id1465185228" className="item" onClick={() => this.setState({ visibleMobile: false})}>
                <Segment style={{ fontSize: 18, padding: 20, margin: '0 0 10px 0', backgroundColor: '#fff', color: '#212121', fontWeight: 300 }} >Download App</Segment>
              </a>

              {/* <a href="https://cannections.ca/blog" className="item" onClick={() => this.setState({ visibleMobile: false})}>
                <Segment style={{ fontSize: 18, padding: 20, margin: '0 0 0 0', backgroundColor: '#fff', color: '#212121', fontWeight: 300 }} >Blog</Segment>
              </a> */}

              <div style={{ width: '100%', marginTop: 20 }}>
                <p>Stay Cannected!</p>
                {this.socialLinks({mobileMenu: true})}
              </div>

            </div>
          </Transition>
          <div 
          style={{ top: 0, left: 0, bottom: 0, backgroundColor: '#000', opacity: 0.5, position: 'fixed', width: '100%', zIndex: 9, display: this.state.visibleMobile ? 'block' : 'none' }}  
          onClick={() => {
               this.setState({ visibleMobile: !this.state.visibleMobile });
             }}
          />

        </div>
    )
  // }
}

  render () {
    const { width } = this.state;
    const isMobile = width <= 900;

    if (isMobile) {
      return (
        <div>
        <div className="ui top fixed menu inverted" style={{ marginBottom: 0, padding: 10, backgroundColor: colors.white, zIndex: 999999999999, boxShadow: this.state.scrollY > 100 ? '0 5px 5px -2px rgba(0,0,0,.1)' : null }}>
        <Link to="/" className="item">
          <img className="ui small image" style={{ marginRight: 4, width: 180 }} src="../logo-text.png" alt='Cannections logo' />
        </Link>
        <i className={this.state.visibleMobile ? 'fas fa-times' : 'fas fa-bars'} 
             style={{ color: this.state.visibleMobile ? colors.blackText : colors.skizzuGreen, fontSize: 24, position: 'absolute', right: 20, top: 30 }}
             onClick={() => {
               this.setState({ visibleMobile: !this.state.visibleMobile });
             }}
          /> 
      </div>
        {this.renderMobileMenu()}
      </div>
      )
    } else {
      return (
        <div className="ui top fixed menu inverted" style={{ marginBottom: 0, padding: 10 , backgroundColor: colors.white, zIndex: 999999999999, boxShadow: this.state.scrollY > 100 ? '0 5px 5px -2px rgba(0,0,0,.1)' : null }}>
          <Link to="/" className="item">
            <img className="ui small image" style={{ marginRight: 4, width: 180 }} src="../logo-text.png" alt='Cannections CMS' />
          </Link>
          {this.renderRightActions()}
        </div>
      );
    }
  }
}


export default Header;
