import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import StrainFinder from './StrainFinder';
import Home from './Home';
import Header from '../components/Header';
import Footer from '../components/Footer';
import firebase from 'firebase';
import '../config/styles.css';
import '../config/stylesStrainDetail.css';
import StrainDetail from './StrainDetail';
import Terms from './Terms';
import Privacy from './Privacy';
import Contact from './Contact';
import ContactDispensary from './ContactDispensary';
import ContactDispensarySuccess from './ContactDispensarySuccess';
import Subscribe from './Subscribe';
import Dispensary from './Dispensary';
import Brand from './Brand';
import DispensaryMap from './DispensaryMap';
import NotFound from './NotFound';
import { ParallaxProvider } from 'react-scroll-parallax';

class App extends Component {
  componentWillMount() {
    const config = {
      apiKey: "AIzaSyAfYb0k4LBvdq7SXVxSH8Zpyu1vPzsC2Io",
      authDomain: "skizzu-f6376.firebaseapp.com",
      databaseURL: "https://skizzu-f6376.firebaseio.com",
      projectId: "skizzu-f6376",
      storageBucket: "skizzu-f6376.appspot.com",
      messagingSenderId: "188197373758",
      appId: "1:188197373758:web:8a36937f45d240c3758e6f",
      measurementId: "G-GS43PVW7G6"
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }

  }

  render() {
    return (
      <div style={{ marginTop: 30 }}>
        <BrowserRouter>
          <Header />
          <ParallaxProvider>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/strains'>
                <Route index element={<StrainFinder />} />
                <Route path=':id' element={<StrainDetail />} />
              </Route>
              {/* <Route path='/strains' element={<StrainFinder />} />
                <Route path='/strains/:id' element={<StrainDetail />} /> */}
              <Route path='/terms' element={<Terms />} />
              <Route path='/privacy' element={<Privacy />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/contactDispensary' element={<ContactDispensary />} />
              <Route path='/contactDispensarySuccess' element={<ContactDispensarySuccess />} />
              <Route path='/subscribe' element={<Subscribe />} />
              <Route path='/dispensary' element={<Dispensary />} />
              <Route path='/brand' element={<Brand />} />
              <Route path='/dispensaryMap' element={<DispensaryMap />} />
              {/* //make404page */}
              <Route path="*" element={<NotFound />} />

            </Routes>
          </ParallaxProvider>
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
