import {
  SEARCH_STRAINS
} from '../actions/types';
//state argument is not application state, only the state the reducer is responsible for
const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEARCH_STRAINS:
      return action.payload;
      default:
  }
  return state;
}
