
// export const EMAIL_CHANGED = 'email_changed';

// export const PASSWORD_CHANGED = 'password_changed';

// export const LOGIN_USER_SUCCESS = 'login_user_sucess';

// export const LOGIN_USER_FAIL = 'login_user_fail';

// export const LOGIN_USER_START = 'login_user_start';
export const STRAINS_FETCH_SUCCESS = 'strains_fetch_success';
export const STRAIN_SELECTED = 'strain_selected';
export const SEARCH_STRAINS = 'search_strains';
export const STRAIN_FILTER_TYPE = 'strain_filter_type';
export const STRAIN_FILTER_EFFECT = 'strain_filter_effect';
//active strain (details)
export const STRAIN_UNMOUNTED = 'strain_unmounted';
//list
export const STRAIN_UNMOUNT = 'strain_unmount';

export const REVIEWS_FETCH_SUCCESS = 'reviews_fetch_success';
export const IMAGES_FETCH_SUCCESS = 'images_fetch_success';

export const REVIEW_COUNT = 'review_count';
export const STRAIN_POST_COUNT = 'strain_post_count';
export const STRAIN_POST_COUNT_UNMOUNT = 'strain_post_count_unmount';

export const AGE_CHECKED = 'age_checked';
